

.customer-data-info--container {
  margin-left: 40px;
}

.customer-data-info--value {
}

.customer-data-info--label {
  font-weight: 600;
  font-size: 12px;
}

.customer-data--container {
  display: flex;
}