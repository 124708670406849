.sidebar-nav {
  min-width: 300px;
  background: var(--se-color-navy);
  text-align: left;
  color: white;
  overflow-y: scroll;
}

.sidebar-ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.sidebar-tab {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  height: 50px;
  color: white;
  align-items: center;
  font-size: 14px;
}

.sidebar-nav-link,
.sidebar-nav-link-selected {
  text-decoration: none;
}
.sidebar-nav-link-selected {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.2);
}


.environment-indicator--container {
  margin: 0 16px;
  font-size: 12px;
}

.environment-indicator--value {
  font-weight: 600;
}