
.customer-layout-links--container {
  margin-bottom: 24px;
}

.customer-layout-links--container > a.active {
  background: lightgrey;
  border-bottom-color: darkgrey;
  color: black;
}

.customer-layout-links--container > a:not(.active):hover {
  border-bottom-color: darkgrey;
}

.customer-layout-links--container > a {
  margin-right: 16px;
  text-decoration: none;
  min-width: 120px;
  display: inline-block;
  text-align: center;
  padding: 4px 0;
  border-radius: 5px 5px 0 0;
  border-bottom: 3px solid transparent;
  font-size: 12px;
  transition: all 0.3s ease;
  color: blue;
}