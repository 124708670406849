.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.se--app-container {
  height: 100vh;
  width: 100vw;
  display: inline-flex;
}

.se--view-container {
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 250px);
}

.se--loading-indicator-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mui-input-label--white {
  color: white !important;
}

.mui-select--white {
  color: white !important;
  width: 250px;
}

.mui-select--white:hover {
  border: 0px solid white;
}

.mui-select--white svg {
  color: white;
}
