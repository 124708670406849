

.dashboard-config-page-option--container.enabled {
  border-left-color: rgb(32, 201, 151);
}

.dashboard-config-page-option--container {
  border-left: 4px solid red;
  padding: 8px 24px;
  margin-bottom: 24px;
}

.dashboard-config-page-option--help {
  font-size: 12px;
}

.dashboard-config-header--info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
}

.dashboard-config-customer-properties--container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dashboard-config-customer-properties--container > div {
  width: 75%;
}